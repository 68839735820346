.ToolsField{
    padding: 0;
    background-color: #e7f1f6;
    width: 6%;
    height: 100vh;
    margin: 0;
    border-right: 2px #cccccc solid;
}
.LeftBorder{
    border-right: 1px #ccc solid;
    min-width: 65px!important;
}
.RightField{
    width: calc(100% - 65px)!important;
}
.WrapperConstructor{
    display: flex!important;
    flex-direction: column!important;
}
.ViewerField{
    padding: 5px;
    background-color: #eae7e7;
    width: 50%;
    margin: 0;
}
iframe{
    width: 100%;
    height: 100vh;
}
.IconBlock{
    padding-top: 12px !important;
    text-align: center!important;
    padding-left: 0!important;
    padding-right: 0!important;
}
.IconBlock svg{
    display: block;
    margin: 0 auto;
}
.ImgCenter{
    margin: 15px auto;
    display: block;
}
.ImgAndTextBox{
    display: flex;
    flex-direction: row;
}
.ImgAndText{
    margin: 0;
    width: 40%;
    height: fit-content;
    overflow: hidden;
    display: block;
}
.TextBox{
    width: 60%;
    display: block;
    padding: 15px;
    text-align: justify;
}
.TextBoxFull{
    width: 100%;
    display: block;
    padding: 15px;
    text-align: justify;
}