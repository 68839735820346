.Wrapper {
  width: 100%;
  height: 100vh;
  background-color: #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body {
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
}